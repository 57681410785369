import {
  Box,
  Button,
  Collapse,
  Divider,
  Grow,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useArticleContext } from '../../contexts';

const BORDER_RADIUS = '8px';
const LIGHT_BLUE_BACKGROUND = '#dce4ef';
const DARK_BLUE_TEXT = '#205493';
const LARGE_TEXT_FONT_SIZE = '1.125rem';
const SMALL_TEXT_FONT_SIZE = '.875rem';
const TRANSITION_TIMEOUT_MS = 750;

interface DocumentDetailsProps {
  title: string;
  published_date_text?: string;
}

const DocumentDetails = ({
  title,
  published_date_text,
}: DocumentDetailsProps) => (
  <Box
    sx={{
      dispay: 'flex',
      flexDirection: 'column',
    }}
  >
    <Typography
      sx={{
        fontSize: SMALL_TEXT_FONT_SIZE,
      }}
    >
      {title}
    </Typography>
    {published_date_text && (
      <Typography
        sx={{
          fontSize: SMALL_TEXT_FONT_SIZE,
          fontStyle: 'italic',
        }}
      >
        {published_date_text}
      </Typography>
    )}
  </Box>
);

const DocumentDetailsList = ({
  documents,
}: {
  documents: DocumentDetailsProps[];
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit sx={{ p: 0, m: 0 }}>
        <List
          aria-label='guideline documents'
          sx={{
            p: 0,
            m: 0,
            gap: 1,
            marginTop: 1,
            marginBottom: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {documents.map((document, index) => (
            <ListItem
              key={index}
              sx={{
                p: 0,
                paddingLeft: 1,
                m: 0,
              }}
            >
              <DocumentDetails {...document} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Button
        onClick={handleToggleExpand}
        size='small'
        aria-label={expanded ? 'collapse' : 'expand'}
        sx={{
          padding: 0,
          marginRight: 'auto',
          color: 'inherit',
          fontSize: SMALL_TEXT_FONT_SIZE,
          fontWeight: 'regular',
        }}
      >
        {expanded ? 'Show less' : `${documents.length} Society Publications`}
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
    </Box>
  );
};

interface GuidelinesSectionProps {
  section_title: string;
  society_name?: string;
  documents?: DocumentDetailsProps[];
  children: React.ReactNode;
}

const GuidelinesSection = ({
  children,
  section_title,
  society_name,
  documents,
}: GuidelinesSectionProps) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(true);
  const { isTyping } = useArticleContext();

  const handleOpenToggle = () => {
    setOpen(!open);
  };

  return (
    <Grow
      in
      timeout={{ enter: TRANSITION_TIMEOUT_MS, exit: TRANSITION_TIMEOUT_MS }}
      appear={isTyping}
    >
      <Box
        sx={{
          marginTop: 1,
          marginBottom: 3,
          backgroundColor: palette.grey[50],
          borderRadius: BORDER_RADIUS,
          border: `1px solid ${palette.grey[300]}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'row',
            backgroundColor: LIGHT_BLUE_BACKGROUND,
            color: DARK_BLUE_TEXT,
            gap: 1,
            padding: 2,
            alignItems: 'center',
            borderRadius: open
              ? `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`
              : BORDER_RADIUS,
            transition: 'border-radius .25s ease',
            cursor: 'pointer',
          }}
          onClick={handleOpenToggle}
        >
          <AssignmentOutlinedIcon />
          <Typography
            sx={{
              fontSize: LARGE_TEXT_FONT_SIZE,
            }}
          >
            {section_title}
          </Typography>
          <Box
            sx={{
              marginLeft: 'auto',
              color: DARK_BLUE_TEXT,
              backgroundColor: 'transparent',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Box>
        <Collapse in={open} timeout={250}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
            }}
          >
            {children}
            {society_name && ( // only show footer if we have a society name
              <>
                <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    opacity: 0.7,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: SMALL_TEXT_FONT_SIZE,
                      fontWeight: 'bold',
                    }}
                  >
                    {society_name}
                  </Typography>
                  {documents &&
                    documents.length > 0 &&
                    (documents.length === 1 ? (
                      <DocumentDetails {...documents[0]} />
                    ) : (
                      <DocumentDetailsList documents={documents} />
                    ))}
                </Box>
              </>
            )}
          </Box>
        </Collapse>
      </Box>
    </Grow>
  );
};

export default GuidelinesSection;
