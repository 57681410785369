import slugifyPkg from 'slugify';
import { TranslateFunction } from './TranslationProvider';

export function slugify(s: string, options: any = undefined): string {
  options = options ?? {};
  return slugifyPkg(s, {
    lower: true,
    strict: true,
    trim: true,
    ...options,
  });
}

export function truncate(s: string, maxLength: number = 20): string {
  if (s.length <= maxLength) {
    return s;
  } else {
    return s.slice(0, maxLength - 3) + '...';
  }
}

export const conjoinStrings = (strings: string[]) => {
  if (strings.length <= 1) {
    return strings.join('');
  } else if (strings.length === 2) {
    return strings.join(' and ');
  } else {
    return strings.slice(0, -1).join(', ') + ', and ' + strings.slice(-1);
  }
};

/**
 * Split english text into sentences.
 *
 * Adapted from https://stackoverflow.com/a/31430385
 */
export function splitSentences(s: string): string[] {
  return s
    .replace(/(\.+|:|!|\?)("*|'*|\)*|}*|]*)(\s|\n|\r|\r\n)/gm, '$1$2|||')
    .split('|||');
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

/**
 * Formats a date to a user-friendly string relative to now
 * @param date - the date to compare to now
 * @param absoluteFormatFn - if the date is >= 7 days, this function will be
 * used to format the absolute date
 * @param t - the translation function to localize text
 */
export function getRelativeDate(
  date: Date,
  absoluteFormatFn: (_date: Date) => string,
  t: TranslateFunction
): string {
  const now = new Date();
  // Get difference in seconds
  const delta = Math.floor((now.getTime() - date.getTime()) / 1000);
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  if (delta < minute) {
    return t('Just now');
  } else if (delta < hour) {
    const diff = Math.floor(delta / minute);
    if (diff === 1) {
      return t('1 minute ago');
    } else {
      return t(`{{N}} minutes ago`, { N: diff });
    }
  } else if (delta < day) {
    const diff = Math.floor(delta / hour);
    if (diff === 1) {
      return t('1 hour ago');
    } else {
      return t(`{{N}} hours ago`, { N: diff });
    }
  } else if (delta < week) {
    const diff = Math.floor(delta / day);
    return diff < 2 ? t('Yesterday') : t(`{{N}} days ago`, { N: diff });
  } else {
    return absoluteFormatFn(date);
  }
}

/**
 * Get x as a single item
 */
export function asItem<T>(x: T | T[]): T {
  return Array.isArray(x) ? x[0] : x;
}

export { useTimerWorkers } from './useTimerWorkers';
export {
  useTranslation,
  TranslationProvider,
  LocalizedText,
} from './TranslationProvider';
export type { TranslateFunction } from './TranslationProvider';
export { useLocalStorage } from './useLocalStorage';
export { useFixedElementPadding } from './useFixedElementPadding';
export * from './whyCited';
export { Queue } from './Queue/Queue';
