import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
  TypewriterPositionUpdate,
  TypewriterState,
  TypewriterAction,
  ResetType,
  UseTypewriterTimings,
  iterable,
  ResetData,
} from './types';
import { useTimerWorkers } from '@xyla/util';

const typewriterReducer = (
  state: TypewriterState,
  action: TypewriterAction
): TypewriterState => {
  switch (action.type) {
    case 'setArraysToWrite': {
      let newCursorPosition: TypewriterPositionUpdate = {};
      switch (action.resetType) {
        case ResetType.All:
          newCursorPosition = {
            currentArray: 0,
            currentIndexInEachArray: [],
          };
          break;
        case ResetType.Specific:
          // If the "resetData" is not provided, then we don't need to reset
          if (!action.resetData) break;

          // If the "resetData" position is beyond the current position, then
          // we don't need to reset anything.
          if (
            action.resetData.currentArray > state.currentArray ||
            (action.resetData.currentArray === state.currentArray &&
              action.resetData.currentIndexInCurrentArray >=
                state.currentIndexInEachArray[state.currentArray])
          )
            break;

          // Only now do we actually reset the position
          const newArrayIndex = action.resetData.currentArray;
          newCursorPosition.currentArray = newArrayIndex;
          // We need to reset all arrays beyond the newArrayIndex, so slice them away
          newCursorPosition.currentIndexInEachArray =
            state.currentIndexInEachArray.slice(0, newArrayIndex);
          newCursorPosition.currentIndexInEachArray[newArrayIndex] =
            action.resetData.currentIndexInCurrentArray;
          break;
        case ResetType.None:
          break;
      }

      return {
        ...state,
        arraysToWrite: action.arrays,
        ...newCursorPosition,
      };
    }
    case 'writeItem': {
      if (
        state.currentArray >= state.arraysToWrite.length ||
        (state.currentArray === state.arraysToWrite.length - 1 &&
          state.currentIndexInEachArray[state.currentArray] >=
            state.arraysToWrite[state.currentArray].length)
      ) {
        return state;
      }

      let newPosition: TypewriterPositionUpdate = {};
      if (
        state.currentIndexInEachArray[state.currentArray] >=
        state.arraysToWrite[state.currentArray].length
      ) {
        const currentIndexInArrays = [...state.currentIndexInEachArray];
        if (!currentIndexInArrays[state.currentArray + 1]) {
          currentIndexInArrays[state.currentArray + 1] = 0;
        }
        newPosition = {
          currentArray: state.currentArray + 1,
          currentIndexInEachArray: currentIndexInArrays,
        };
      } else {
        const currentIndexInArrays = [...state.currentIndexInEachArray];
        if (!currentIndexInArrays[state.currentArray]) {
          currentIndexInArrays[state.currentArray] = 0;
        }
        currentIndexInArrays[state.currentArray] += 1;
        newPosition = {
          currentIndexInEachArray: currentIndexInArrays,
        };
      }

      return {
        ...state,
        ...newPosition,
      };
    }
    case 'writeAll': {
      return {
        ...state,
        currentArray: state.arraysToWrite.length,
        currentIndexInEachArray: state.arraysToWrite.map((arr) => arr.length),
      };
    }
  }
};

const defaultTypewriterState: TypewriterState = {
  arraysToWrite: [],
  currentArray: 0,
  currentIndexInEachArray: [],
};

interface UseTypewriterOutput {
  arraysWritten: iterable[];
  running: boolean;
  done: boolean;
  currentArray: number;
  setArraysToWrite: (
    _arrays: iterable[],
    _resetType: ResetType,
    _resetData?: ResetData
  ) => void;
  addArraysToWrite: (_arrayOrArrays: iterable | iterable[]) => void;
  startTypewriter: () => void;
  stopTypewriter: () => void;
  typewriterWriteAll: () => void;
}
export const useTypewriter = (
  timings: UseTypewriterTimings,
  onComplete?: () => void
): UseTypewriterOutput => {
  // The actual state of the typewriter
  const [typewriterState, dispatch] = useReducer(
    typewriterReducer,
    defaultTypewriterState
  );

  // Derived state from what the typewriterState actually needs to maintain
  const arraysWritten = typewriterState.currentIndexInEachArray.map(
    (x, index) => {
      if (!typewriterState.arraysToWrite[index]) {
        return [];
      }
      return typewriterState.arraysToWrite[index].slice(0, x);
    }
  );

  const done =
    typewriterState.currentArray >= typewriterState.arraysToWrite.length ||
    typewriterState.arraysToWrite
      .slice(typewriterState.currentArray)
      .map((arr, indexOffset) => {
        const index = typewriterState.currentArray + indexOffset; // since we sliced, we need to add the offset back
        if (arr.length === 0) {
          return true;
        } else if (!typewriterState.currentIndexInEachArray[index]) {
          return false;
        } else {
          return arr.length <= typewriterState.currentIndexInEachArray[index];
        }
      })
      .every(Boolean);

  const isEndOfLine = useRef(false);

  useEffect(() => {
    // -1 here so that we don't trigger the long end of line delay when we're
    // at the end of the last line
    if (
      typewriterState.currentArray <
      typewriterState.arraysToWrite.length - 1
    ) {
      isEndOfLine.current =
        typewriterState.currentIndexInEachArray[
          typewriterState.currentArray
        ] ===
        typewriterState.arraysToWrite[typewriterState.currentArray].length - 1;
    }
  }, [typewriterState]);

  const { setTimeoutWorker, clearTimeoutWorker } = useTimerWorkers();

  // Timer to write the next character
  const timer = useRef<number | null>(null);
  const [runningInternal, setRunningInternal] = useState(false);
  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearTimeoutWorker(timer.current);
      timer.current = null;
    }
    setRunningInternal(false);
  }, [setRunningInternal, clearTimeoutWorker]);

  // Stop the timer when the typewriter is done
  useEffect(() => {
    if (done) {
      stopTimer();
      onComplete && onComplete();
    }
  }, [done, stopTimer, onComplete, runningInternal]);

  // Exposed actions
  const setArraysToWrite = useCallback(
    (arrays: iterable[], resetType: ResetType, resetData?: ResetData) => {
      stopTimer();
      dispatch({ type: 'setArraysToWrite', arrays, resetType, resetData });
    },
    [stopTimer]
  );

  const addArraysToWrite = useCallback(
    (arrayOrArrays: iterable | iterable[]) => {
      stopTimer();

      let newArrays: iterable[];
      if (Array.isArray(arrayOrArrays) && Array.isArray(arrayOrArrays[0])) {
        newArrays = [...typewriterState.arraysToWrite, ...arrayOrArrays];
      } else {
        if (typewriterState.arraysToWrite.length > 0) {
          newArrays = [
            ...typewriterState.arraysToWrite.slice(
              0,
              typewriterState.arraysToWrite.length - 1
            ),
            [
              ...typewriterState.arraysToWrite[
                typewriterState.arraysToWrite.length - 1
              ],
              ...arrayOrArrays,
            ],
          ];
        } else {
          newArrays = [arrayOrArrays];
        }
      }

      dispatch({
        type: 'setArraysToWrite',
        arrays: newArrays,
        resetType: ResetType.None,
      });
    },
    [stopTimer, typewriterState.arraysToWrite]
  );

  const startTypewriter = useCallback(() => {
    const writeCharacter = () => {
      dispatch({ type: 'writeItem' });

      let delayStart = 0;
      let delayEnd = 0;
      if (isEndOfLine.current) {
        delayStart = timings.newlineDelayMsStart;
        delayEnd = timings.newlineDelayMsEnd;
      } else {
        // Compute a random delay
        const useLongWait = Math.random() < timings.longWaitFreq;
        if (useLongWait) {
          delayStart = timings.longWaitDelayMsStart;
          delayEnd = timings.longWaitDelayMsEnd;
        } else {
          delayStart = timings.delayMsStart;
          delayEnd = timings.delayMsEnd;
        }
      }
      const delay = Math.random() * (delayEnd - delayStart) + delayStart;

      // Set up the new timer
      const newTimer = setTimeoutWorker(() => {
        writeCharacter();
      }, delay);
      timer.current = newTimer;
    };
    setRunningInternal(true);

    timer.current = setTimeoutWorker(() => {
      writeCharacter();
    }, timings.initialDelayMs || 0);
  }, [timings, setRunningInternal, setTimeoutWorker]);

  const typewriterWriteAll = useCallback(() => {
    stopTimer();
    dispatch({ type: 'writeAll' });
  }, [stopTimer]);

  const stopTypewriter = useCallback(() => {
    stopTimer();
  }, [stopTimer]);

  return {
    arraysWritten,
    running: runningInternal && !done, // Don't report running if we're done
    done,
    currentArray: typewriterState.currentArray,
    setArraysToWrite,
    addArraysToWrite,
    startTypewriter,
    typewriterWriteAll,
    stopTypewriter,
  };
};
